import AppImage from '@theme/components/atom/AppImage'
import BranchLine from '@theme/components/cart/BranchLine'
import DeliveryDate from '@theme/components/shop/DeliveryDate'
import Loader from '@theme/components/utils/Loader'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'

export default {
  mixins: [LoadingMixin],
  components: {
    AppImage,
    BranchLine,
    DeliveryDate,
    Loader,
    Modal,
  },
  props: {
    shipper: Object,
  },
  data() {
    return {
      query: '',
      branches: [],
    }
  },
  async mounted() {
    this.startLoading()
    const result = await this.$store.dispatch('cart/GET_BRANCHES', {
      query: this.query,
      shipperId: this.shipper.id,
      perPage: 50,
    })
    this.branches = result.data
    this.stopLoading()
  },
  methods: {
    select(branch) {
      this.$store.commit('cart/SET_BRANCH', branch)
    },
  },
}
