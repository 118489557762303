import AppTitle from '@theme/components/atom/AppTitle'

export default {
  components: {
    AppTitle,
  },
  props: {
    branch: Object,
    shipper: Object,
  },
  data() {
    return {
      address: null,
      branchDetailModalComponent: null,
    }
  },
  mounted() {
    this.address = this.composeAddress()
  },
  computed: {
    hasDetailModal() {
      return this.$themeSettings.components.BranchLine.modalDetail.enable && !!this.branch.contentId
    },
  },
  methods: {
    composeAddress() {
      let result = ''
      if (this.$themeSettings.components.BranchLine.address.street) {
        result = `${result} ${this.branch.address.street}`
      }
      if (this.$themeSettings.components.BranchLine.address.city) {
        result = `${result}, ${this.branch.address.city}`
      }
      if (this.$themeSettings.components.BranchLine.address.postcode) {
        result = `${result}, ${this.branch.address.postcode}`
      }
      if (this.$themeSettings.components.BranchLine.address.country) {
        result = `${result}, ${this.branch.address.countryName}`
      }
      if (this.$themeSettings.components.BranchLine.address.openingHours) {
        result = `${result}, ${this.$options.filters.capitalize(this.branch.businessHours)}`
      }
      return result
    },
    async openBranchDetailModal() {
      this.branchDetailModalComponent = (await import('@theme/components/cart/BranchDetailModal')).default
      this.$nextTick(() => {
        this.$refs.branchDetailModal.open()
      })
    },
  },
}
