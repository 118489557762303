import { find, isEmpty, isEqual, map } from 'lodash'
import BranchSelectorInline from '~/themes/base/components/cart/BranchSelectorInline'

export default {
  extends: BranchSelectorInline,
  data() {
    return {
      branchesContent: [],
    }
  },
  watch: {
    branches: {
      handler() {
        if (this.shipper.type === 'PERSONAL') {
          this.fetchBranchesContent()
        }
      },
    },
  },
  methods: {
    async fetchBranchesContent() {
      const branchesContent = await this.$store.dispatch('branch/LOAD_BRANCH_CONTENT_BY_IDS', {
        ids: map(this.branches, branch => branch.id),
      })
      this.branchesContent = branchesContent
    },
    getBranchClosedDescription(branch) {
      let value = ''

      if (!isEmpty(this.branchesContent)) {
        const dates = this.parseClosedDates(branch)

        if (dates.from && dates.to) {
          let translationCode = 'BranchSelectorInline.branch.closedDescription'

          if (dates.from.isSame(dates.to, 'day')) {
            translationCode = 'BranchSelectorInline.branch.closedDescriptionOneDay'
          }

          value = this.$t(translationCode, {
            from: dates.from.format('D. M. YYYY'),
            to: dates.to.format('D. M. YYYY'),
            closestDate: dates.closestDate.format('D. M. YYYY'),
          })
        }
      }

      return value
    },
    parseClosedDates(branch) {
      const branchContent = find(this.branchesContent, content => content.store_id === branch.id)
      let fromDate = branchContent && branchContent.branch_closed_from && this.$moment(branchContent.branch_closed_from)
      let toDate = branchContent && branchContent.branch_closed_to && this.$moment(branchContent.branch_closed_to)
      let closestDate

      if (toDate && toDate.hour() >= 12) {
        toDate.add(1, 'day')
      }
      if (fromDate && fromDate.hour() >= 12) {
        fromDate.add(1, 'day')
      }

      if (toDate) {
        closestDate = toDate.clone()
        closestDate.add(1, 'day')

        while (
          closestDate.weekday() > 4 ||
          find(this.$themeSettings.global.holidayDays, day =>
            isEqual([closestDate.date(), closestDate.month() + 1], day),
          )
        ) {
          closestDate.add(1, 'day')
        }
      }

      return {
        from: fromDate,
        to: toDate,
        closestDate,
      }
    },
    isBranchClosed(branch) {
      let value = false

      if (!isEmpty(this.branchesContent)) {
        const dates = this.parseClosedDates(branch)

        if (dates.from && dates.to) {
          const now = this.$moment(this.shipper.delivery.nearestDeliveryDate)
          value = now.isBetween(dates.from, dates.to, 'days', '[]')
        }

        return value
      }
    },
    showBranchDeliveryDate(branch) {
      return this.$themeSettings.components.BranchSelectorInline.showDeliveryDate || this.isBranchClosed(branch)
    },
  },
}
